body {
  margin: 0;
  background: #C0C0C0 !important; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form {
	background: white !important;
	padding: 10px 10px 10px 10px;
	border-style: solid;
  	border-width: 1px;
  	box-shadow: 5px 10px #888888;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  margin-top: -40px;
  margin-left:  -30px;
  width: 200px;
  height: 200px;
}